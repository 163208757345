
 @font-face {
  font-family: 'Futura Bold';
  src: url('fonts/12dbb46a-1b0b-40ee-80a6-4141c349b55f.woff2') format('woff2'); /* Legacy iOS */ 
}

@font-face {
  font-family: 'Futura';
  src: url('fonts/5f081b13-e570-4277-9283-091a6f6cab04.woff2') format('woff2'); /* Legacy iOS */ 
}

html {
  font-family: 'Futura', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.625em;
}

body {
    margin: 0;
    font-family: 'Futura', 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.6rem;
    line-height: 1.75;
}

p {
  font-size: 1.6rem;
  line-height: 1.75;
}
h1 {
    font-family: 'Futura Bold';    
    font-size: 3.4rem;
    line-height: 1.25;
    margin: 0 0 2rem;
}
h2 {
    font-family: 'Futura Bold';
    font-size: 2.2rem;
    line-height: 1.2;
    margin: 0 0 2rem;
}

h3 {
  font-family: 'Futura Bold';
    font-size: 1.6rem;
    line-height: 1.75;
    margin: 0 0 1.5rem;
}

code {
    font-family: 'Futura', 'Roboto', sans-serif;
}
